import Loader from '#lib/Loader';

import { getMintRequestForPolicy, getOfferMetadata } from '#lib/firestore';

import type {
  TransactionUnspentOutput,
  Value,
  PlutusData,
  PlutusList,
} from '@emurgo/cardano-serialization-lib-browser';

type Royalty777 = {
  addr: string;
  rate: string;
  pct?: string;
};
type Extended777 = ({ tag: string } & Royalty777)[];

import getAbsoluteURL from '../getAbsoluteUrl';
import type { components } from '@blockfrost/blockfrost-js/lib/types/OpenApi';
import { Cardano } from '#types/cardano';
import constants from '#lib/constants';
import { Contract } from '#types/index';

export type TradeUtxo = {
  lovelace: string;
  datum: PlutusData | null;
  dropspotAddress: string;
  policy: string;
  token: string;
  utxo: TransactionUnspentOutput;
  metadata?: Record<number | '_uid', string> &
    Record<'_metadata', OFFER_DATUM_TYPE>;
};
type DisAmount = DisbType & { amount: number };

export type ProtocolParams = components['schemas']['epoch_param_content'];

export type OFFER_DATUM_TYPE = {
  ownerAddress: Uint8Array;
  price: number;
  policy: string;
  assetName: string;
  startDatePOSIX: string;
};

export type OfferOptions = {
  disbursements?: RoyaltyMetadata[];
  policy: string;
  assetName: string;
  askingPrice: number;
  assetId: string;
};

export type RelistOptions = {
  utxo: TradeUtxo;
  startDate?: number;
  disbursements?: RoyaltyMetadata[];
  askingPrice: number;
  assetId: string;
};

type ProtocolParamsType = {
  epoch: number;
  min_fee_a: number;
  min_fee_b: number;
  max_block_size: number;
  max_tx_size: number;
  max_block_header_size: number;
  key_deposit: string;
  pool_deposit: string;
  e_max: number;
  n_opt: number;
  a0: number;
  rho: number;
  tau: number;
  decentralisation_param: number;
  extra_entropy: null;
  protocol_major_ver: number;
  protocol_minor_ver: number;
  min_utxo: string;
  min_pool_cost: string;
  nonce: string;
  price_mem: number;
  price_step: number;
  max_tx_ex_mem: string;
  max_tx_ex_steps: string;
  max_block_ex_mem: string;
  max_block_ex_steps: string;
  max_val_size: string;
  collateral_percent: number;
  max_collateral_inputs: number;
  coins_per_utxo_word: string;
  coins_per_utxo_size: string;
  era: 'Alonzo' | 'Vasil';
};

interface RoyaltyMetadata {
  pct?: string;
  addr: string | string[];
  rate: string;
}
const MAX_ATTEMPTS = 100;
const DATUM_LABEL = 409;
const ADDRESS_LABEL = 406;

const DROPSPOT_ADDRESS =
  constants.NEXT_PUBLIC_DROPSPOT_ADDRESS || 'NOT_CONFIGURED';

const MIN_LOVELACE = 2_000_000;

/**
 *
 * @param {string} txHash Transaction Id
 * @returns
 */
export const awaitConfirmation = (txHash?: string): Promise<boolean> => {
  if (!txHash) return Promise.resolve(false);

  let attempts = 0;
  //
  const isConfirmed = async () => {
    try {
      const txn = await blockfrost<components['schemas']['tx_content']>(
        `/plutus/txns/${txHash}`,
        false
      );

      if (txn) {
        return true;
      }
    } catch (_) {
      console.log('Error getting txn', _);
    }

    return false;
  };

  const p = new Promise<boolean>((resolve) => {
    const x = setInterval(() => {
      attempts += 1;
      if (attempts > MAX_ATTEMPTS) {
        clearInterval(x);
        resolve(false);
      }

      isConfirmed().then((confirmed) => {
        if (confirmed) {
          clearInterval(x);
          resolve(true);
        }
      });
    }, 5000);
  });

  return p;
};

/** DATUM Generation */

// const ASSET = (assetName: string, policy: string) => {
//   const fieldsInner = Loader.Cardano.PlutusList.new();
//   fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(policy)));
//   fieldsInner.add(
//     Loader.Cardano.PlutusData.new_bytes(Buffer.from(assetName, 'utf-8'))
//   );

//   return Loader.Cardano.PlutusData.new_constr_plutus_data(
//     Loader.Cardano.ConstrPlutusData.new(
//       Loader.Cardano.Int.new_i32(DATUM_TYPE.Asset),
//       fieldsInner
//     )
//   );
// };

// const RELIST = (index: string) => {
//   const redeemerData = Loader.Cardano.PlutusData.new_constr_plutus_data(
//     Loader.Cardano.ConstrPlutusData.new(
//       Loader.Cardano.BigNum.from_str('1'),
//       Loader.Cardano.PlutusList.new()
//     )
//   );

//   const redeemer = Loader.Cardano.Redeemer.new(
//     Loader.Cardano.RedeemerTag.new_spend(),
//     Loader.Cardano.BigNum.from_str(index),
//     redeemerData,
//     Loader.Cardano.ExUnits.new(
//       Loader.Cardano.BigNum.from_str('7000000'),
//       Loader.Cardano.BigNum.from_str('3000000000')
//     )
//   );
//   return redeemer;
// };

type DisbType = {
  percent: number;
  address: Uint8Array | undefined;
};

type OfferDataType = {
  royalties: DisbType[];
  disbursements: DisbType[];
  ownerAddress: string;
  price: number;
  policy: string;
  assetName: string;
  startDatePOSIX: string;
};

export const OFFER = ({
  royalties,
  disbursements,
  assetName,
  ownerAddress,
  policy,
  price,
  startDatePOSIX,
}: OfferDataType) => {
  console.log('===============IN OFFER==================');
  console.log(
    JSON.stringify({
      royalties,
      disbursements,
      assetName,
      ownerAddress,
      policy,
      price,
      startDatePOSIX,
    })
  );
  const royaltiesList = Loader.Cardano.PlutusList.new();
  const disList = Loader.Cardano.PlutusList.new();

  royalties.map((royalty) => royaltiesList.add(disbursementToPlutus(royalty)));
  disbursements.map((dis) => disList.add(disbursementToPlutus(dis)));

  const fieldsInner = Loader.Cardano.PlutusList.new();

  // Royalties
  fieldsInner.add(Loader.Cardano.PlutusData.new_list(royaltiesList));

  // Disbursements
  fieldsInner.add(Loader.Cardano.PlutusData.new_list(disList));

  // tradeOwner
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(ownerAddress)));

  // amount
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${price}`)
    )
  );

  // policy
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(policy)));

  // token
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_bytes(Buffer.from(assetName, 'utf-8'))
  );

  // Start Date
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(startDatePOSIX)
    )
  );

  const datum = Loader.Cardano.PlutusData.new_constr_plutus_data(
    Loader.Cardano.ConstrPlutusData.new(
      Loader.Cardano.BigNum.from_str(DATUM_TYPE.Offer.toString()),
      fieldsInner
    )
  );
  console.log('=============OUT OFFER===================');
  console.log(toHex(datum.to_bytes()));
  return datum;
};

function disbursementToPlutus(dis: DisbType) {
  console.log('====================================');
  console.log(JSON.stringify(dis));
  console.log('PCT: ', `${percentageToContractPCT(dis.percent)}`);
  console.log('====================================');
  if (!dis.address) throw new Error('Address is undefined'); // Throw?

  const fields = Loader.Cardano.PlutusList.new();

  console.log('To Address: ', toHex(dis.address));
  const address = Loader.Cardano.Address.from_bytes(dis.address);
  console.log('Got Address: ', toHex(address.to_bytes()));

  const baseAddress = Loader.Cardano.BaseAddress.from_address(address);
  if (!baseAddress) throw new Error('Unable to get Base Address');

  const keyHash = baseAddress.payment_cred().to_keyhash();

  if (!keyHash) throw new Error('Keyhash is undefined. Bad Address?');

  fields.add(Loader.Cardano.PlutusData.new_bytes(keyHash.to_bytes()));

  fields.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${percentageToContractPCT(dis.percent)}`)
    )
  );

  return Loader.Cardano.PlutusData.new_constr_plutus_data(
    Loader.Cardano.ConstrPlutusData.new(
      Loader.Cardano.BigNum.from_str('0'),
      fields
    )
  );
}

export const OFFER2 = ({
  royaltyOwner,
  royaltyPCT,
  ownerAddress,
  price,
  policy,
  assetName,
  startDatePOSIX,
}: {
  royaltyOwner: string;
  royaltyPCT: string;
  ownerAddress: string;
  price: number;
  policy: string;
  assetName: string;
  startDatePOSIX: string;
}) => {
  /*
    royaltyOwner :: !PubKeyHash,     -- Wallet address for Dropspot
    royaltyPCT :: !Integer,          -- Percentage to be paid to the market place owner
    tradeOwner :: !PubKeyHash,       -- Owners Wallet Public Key hash
    amount :: !Integer,              -- Minimum Trade Amount for Token
    policy :: !BuiltinByteString,    -- Policy of NFT that we are selling
    token :: !BuiltinByteString,     -- NFT that we are selling
    startDate :: !POSIXTime          -- The NFT cannot be purchased before this Date
  */

  console.log('royaltyOwner', royaltyOwner);
  console.log('ownerAddress', ownerAddress);

  const fieldsInner = Loader.Cardano.PlutusList.new();

  // royaltyOwner
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(royaltyOwner)));

  const royaltyPctPlutus = percentageToContractPCT(
    Number.parseFloat(royaltyPCT)
  );

  // royaltyPCT
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${royaltyPctPlutus}`)
    )
  );

  // tradeOwner
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(ownerAddress)));

  // amount
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(`${price}`)
    )
  );

  // policy
  fieldsInner.add(Loader.Cardano.PlutusData.new_bytes(fromHex(policy)));

  // token
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_bytes(Buffer.from(assetName, 'utf-8'))
  );

  // Start Date
  fieldsInner.add(
    Loader.Cardano.PlutusData.new_integer(
      Loader.Cardano.BigInt.from_str(startDatePOSIX)
    )
  );

  const datum = Loader.Cardano.PlutusData.new_constr_plutus_data(
    Loader.Cardano.ConstrPlutusData.new(
      Loader.Cardano.BigNum.from_str(DATUM_TYPE.Offer.toString()),
      fieldsInner
    )
  );
  console.log(toHex(datum.to_bytes()));
  return datum;
};

const DATUM_TYPE = {
  Offer: 0,
};

/** Utility Functions **/

function splitStr(str: string, length = 63) {
  let tmp = str;
  const result = [];

  while (tmp.length > length) {
    result.push(tmp.substring(0, length));
    tmp = tmp.substring(length);
  }
  result.push(tmp);
  return result;
}

export function percentageToContractPCT(percent: number) {
  return Math.floor(10 / (percent / 100));
}

export const fromHex = (hex: string) => Buffer.from(hex, 'hex');
export const toHex = (bytes: Uint8Array) => Buffer.from(bytes).toString('hex');
export const fromAscii = (text: string) =>
  Buffer.from(text, 'utf-8').toString('hex');

export const assetsToValue = (
  assets: {
    unit: string;
    quantity: string;
  }[]
) => {
  console.log('In assetsToValue', assets);
  const multiAsset = Loader.Cardano.MultiAsset.new();
  const lovelace = assets.find((asset) => asset.unit === 'lovelace');
  const policies = [
    ...new Set(
      assets
        .filter((asset) => asset.unit !== 'lovelace')
        .map((asset) => asset.unit.slice(0, 56))
    ),
  ];
  policies.forEach((policy) => {
    const policyAssets = assets.filter(
      (asset) => asset.unit.slice(0, 56) === policy
    );
    const assetsValue = Loader.Cardano.Assets.new();
    policyAssets.forEach((asset) => {
      assetsValue.insert(
        Loader.Cardano.AssetName.new(Buffer.from(asset.unit.slice(56), 'hex')),
        Loader.Cardano.BigNum.from_str(asset.quantity)
      );
    });
    multiAsset.insert(
      Loader.Cardano.ScriptHash.from_bytes(Buffer.from(policy, 'hex')),
      assetsValue
    );
  });
  const value = Loader.Cardano.Value.new(
    Loader.Cardano.BigNum.from_str(lovelace ? lovelace.quantity : '0')
  );
  if (assets.length > 1 || !lovelace) value.set_multiasset(multiAsset);
  return value;
};

export const valueToAssets = (value: Value) => {
  const assets = [];
  assets.push({ unit: 'lovelace', quantity: value.coin().to_str() });
  const ma = value.multiasset();
  if (ma) {
    const multiAssets = ma.keys();

    for (let j = 0; j < multiAssets.len(); j++) {
      const policy = multiAssets.get(j);
      const policyAssets = ma.get(policy);
      if (!policyAssets) {
        continue;
      }
      const assetNames = policyAssets.keys();
      for (let k = 0; k < assetNames.len(); k++) {
        const policyAsset = assetNames.get(k);
        const quantity = policyAssets.get(policyAsset);
        const asset = `${toHex(policy.to_bytes())}${Buffer.from(
          policyAsset.name()
        ).toString('utf-8')}`;
        assets.push({
          unit: asset,
          quantity: quantity?.to_str() || '0',
        });
      }
    }
  }
  return assets;
};

// Debugging Functions
// function handleDatum(data: PlutusData) {
//   switch (data.kind()) {
//     case Loader.Cardano.PlutusDataKind.Integer:
//       console.log('Data Int', data.as_integer());
//       break;
//     case Loader.Cardano.PlutusDataKind.Map:
//       console.log('Data Map', data.as_map());
//       const m = data.as_map();
//       if (m) {
//         const keys = m.keys();
//         for (let i = 0; i < keys.len(); i++) {
//           keys.get(i);

//           const data = m.get(keys.get(i));
//         }
//       }
//       break;
//     case Loader.Cardano.PlutusDataKind.List:
//       console.log('Data List', i, data.as_list());
//       break;
//     case Loader.Cardano.PlutusDataKind.Bytes:
//       console.log('Data Bytes', i, data.as_bytes());
//       break;
//     case Loader.Cardano.PlutusDataKind.ConstrPlutusData:
//       console.log('Data', i, data.as_constr_plutus_data());
//       break;
//   }
// }

// function showDatums(datums: PlutusList) {
//   for (let i = 0; i < datums.len(); i++) {
//     const data = datums.get(i);

//     console.log(toHex(data.to_bytes()));
//   }
// }

type ExUnitReturn = {
  result: {
    EvaluationResult: {
      [key: string]: {
        memory: number;
        steps: number;
      };
    };
  };
};

// I need to work out how to tie this back in!
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function evaluateTxn(txnCbor: string): Promise<ExUnitReturn> {
  const response = await fetch(
    getAbsoluteURL(`/api/blockfrost/plutus/utils/txs/evaluate`),
    {
      method: 'POST',
      body: txnCbor,
      headers: {
        'Content-Type': 'application/cbor',
      },
    }
  );

  if (!response.ok) {
    console.error(await response.text());
    throw new Error('Unable to talk with blockfrost; check server logs!');
  }

  const data = await response.json();

  if (!isExUnitReturn(data))
    throw new Error('Unable to get ExUnits for Transaction');

  return data;
}

function isExUnitReturn(data: unknown): data is ExUnitReturn {
  return (
    (data as ExUnitReturn).result !== undefined &&
    (data as ExUnitReturn).result.EvaluationResult !== undefined
  );
}

export async function blockfrost<T>(path: string, showErr = true): Promise<T> {
  const response = await fetch(getAbsoluteURL(`/api/blockfrost/${path}`), {
    headers: {
      accept: 'application/json',
    },
  });

  if (!response.ok) {
    showErr && console.error(await response.text());
    throw new Error('Unable to talk with blockfrost; check server logs!');
  }
  const json = await response.json();
  return json as unknown as T;
}

async function getTokenListingDetails(
  utxo: components['schemas']['address_utxo_content'][number],
  policy: string,
  tokenName: string
) {
  if (!utxo.data_hash) throw new Error('Token has been bombed');
  const asset = `${policy}${fromAscii(tokenName)}`;

  try {
    const offer = await getOfferMetadata(utxo.data_hash);
    return {
      price: offer._metadata.price,
      tradeOwner: offer._uid,
    };
  } catch (_) {
    const mintRequests = await getMintRequestForPolicy(policy);
    if (
      mintRequests.length === 0 ||
      !mintRequests[0].assets ||
      mintRequests[0].assets.length === 0
    ) {
      throw new Error('No Minting Requests found');
    }
    if (mintRequests[0].type === 'Redemption') {
      throw new Error('Incorrect Minting type for this process.');
    }

    const token = mintRequests[0].tokens?.find(
      (t) => `${t.policy}${fromAscii(t.tokenName)}` === asset
    );
    const a = mintRequests[0].assets.find((a) => a.assetId === token?.assetId);

    if (!a) {
      throw new Error('Asset not found');
    }
    return {
      price: a.price,
      tradeOwner: mintRequests[0].initialSaleInfo.tradeOwnerAddress,
    };
  }
}

/**
 *
 * @param policy Policy ID
 * @param assetName Asset Name (in UTF8)
 * @param contracts An array of Dropspot Supported Contracts
 * @returns The contracts where this asset is available for Purchase.
 */
export async function getAssetAddresses(
  policy: string,
  assetName: string,
  contracts: Contract[]
) {
  const token = `${policy}${Buffer.from(assetName).toString('hex')}`;

  const addresses = await blockfrost<components['schemas']['asset_addresses']>(
    `plutus/asset/${token}/addresses`
  );

  return contracts.filter((a) =>
    addresses.find((c) => c.address === a.address)
  );
}

export async function getAssetListingDetails(
  contractAddress: string,
  policy: string,
  assetName: string
) {
  const token = `${policy}${Buffer.from(assetName).toString('hex')}`;

  const utxos = await blockfrost<components['schemas']['address_utxo_content']>(
    `plutus/${contractAddress}/utxos/${token}?count=1&page=1`
  );

  if (utxos.length !== 1) throw new Error('Not for sale');

  return await getTokenListingDetails(utxos[0], policy, assetName);
}

type BF_UTXO = components['schemas']['address_utxo_content'][number];
function flattenIfArray(s?: string | string[]): string | undefined {
  if (!s) return undefined;
  return Array.isArray(s) ? s.join('') : s;
}

export async function addressToPaymentKeyHash(addr: string) {
  await Loader.load();
  const address = Loader.Cardano.Address.from_bech32(addr);

  const ea = Loader.Cardano.EnterpriseAddress.from_address(address);

  let hash = ea?.payment_cred().to_keyhash()?.to_bytes();

  if (hash) {
    return toHex(hash);
  }

  const ba = Loader.Cardano.BaseAddress.from_address(address);

  hash = ba?.payment_cred().to_keyhash()?.to_bytes();

  if (hash) {
    return toHex(hash);
  }

  return 'Unknown';
}

/*
lovelacePercentage am p =
if p > 0
then if result < minLovelace then minLovelace else result
else 0 -- Prevent Divide By Zero
where
result = (am * 10) `Plutus.divide` p
*/
const llPct = (amt: number, pct: number) => {
  if (pct === 0) return 0;

  const split = Math.floor((amt * 10) / pct);

  return split < MIN_LOVELACE ? MIN_LOVELACE : split;
};

function disDatumToArray(splitAmount: number, datum?: PlutusList) {
  const arr: DisAmount[] = [];

  if (datum) {
    for (let i = 0; i < datum?.len(); i++) {
      const disb = datum.get(i).as_constr_plutus_data()?.data();

      const address = disb?.get(0).as_bytes();
      const percent = disb?.get(1).as_integer();

      if (!address || !percent) {
        throw new Error('Record not setup right');
      }

      arr.push({
        address: address,
        percent: Number.parseInt(percent?.to_str()),
        amount: llPct(splitAmount, Number.parseInt(percent?.to_str())),
      });
    }
  }

  return arr;
}

export async function isNftOwner(
  wallet: Cardano,
  policy: string,
  assetName: string
) {
  await Loader.load();

  const encAssetName = fromAscii(assetName);

  // getUtxos has params that could be useful.
  const utxos = ((await wallet.getUtxos()) || []).map((utxo) =>
    Loader.Cardano.TransactionUnspentOutput.from_bytes(fromHex(utxo))
  );

  const utxo = utxos.find((utxo) => {
    const multiAsset = utxo.output().amount().multiasset();

    if (multiAsset) {
      const maKeys = multiAsset.keys();

      for (let i = 0; i < maKeys.len(); i++) {
        const maPolicy = toHex(maKeys.get(i).to_bytes());

        if (maPolicy === policy) {
          const asset = multiAsset.get(maKeys.get(i));

          const assetNames = asset?.keys();

          if (assetNames?.len()) {
            for (let j = 0; j < assetNames.len(); j++) {
              const maAssetName = assetNames.get(j);
              if (encAssetName === toHex(maAssetName.name())) {
                return true;
              }
            }
          }
        }
      }
    }
  });

  return !!utxo;
}

import firebase from 'firebase/app';
import 'firebase/auth';

export async function updateAssetListing(
  assetId: string,
  price: number | null,
  txHash: string | null,
  marketStatus: 'LISTED' | 'UNLISTED' | 'OFFER'
) {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) return;

  const url = getAbsoluteURL('/api/updateAssetListing');

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await currentUser.getIdToken()}`,
    },
    body: JSON.stringify({
      assetId,
      price,
      txHash,
      marketStatus,
    }),
  });

  if (!result.ok) {
    console.error(await result.json());
  }
}

/*----------*/
/*----------*/
/*----------*/

async function _getOffer(
  contractAddress: string,
  policy: string,
  tokenName: string
): Promise<TradeUtxo | null> {
  console.log(
    'DropSpotMarket',
    'In getOffer',
    contractAddress,
    policy,
    tokenName
  );
  const asset = `${policy}${fromAscii(tokenName)}`;

  const offerUtxo = await getUtxo(contractAddress, asset, policy, tokenName);

  if (offerUtxo && offerUtxo.length === 1) {
    // Validate the Offer Datum to ensure that for instance it is requesting the minimum amounts etc.

    return {
      policy,
      token: tokenName,
      ...offerUtxo[0],
      lovelace: `${offerUtxo[0].metadata?._metadata?.price || '100000000'}`,
    };
  }

  return null;
}

export async function hexAddressToBech32(addr: string) {
  console.log('In hexAddressToBech32', addr);
  await Loader.load();
  const address = Loader.Cardano.Address.from_hex(addr);

  console.log(
    'Out hexAddressToBech32',
    address.to_bech32(address.network_id() === 0 ? 'addr_test' : 'addr')
  );
  return address.to_bech32(address.network_id() === 0 ? 'addr_test' : 'addr');
}

async function getOnchainMetadata(utxo: BF_UTXO) {
  type DataType = {
    tx_hash: string;
    /** Content of the JSON metadata */
    json_metadata: { [index: string]: string | string[] } | string | string[];
    label: string;
  };
  const metadata = await blockfrost<DataType[]>(
    `/plutus/txns/${utxo.tx_hash}/metadata`
  );

  const m = metadata.filter(
    (m) =>
      m.label === DATUM_LABEL.toString() || m.label === ADDRESS_LABEL.toString()
  );

  // Verify that the metadata is correct format
  return m;
}

async function getUtxo(
  contractAddress: string,
  asset: string,
  policy?: string,
  tokenName?: string
) {
  await Loader.load();
  const utxos = await blockfrost<BF_UTXO[]>(
    `/plutus/${contractAddress}/utxos/${asset}?count=1&page=1`
  );

  return await Promise.all(
    utxos.map(async (utxo) => {
      let metadata: Record<number | '_uid', string> &
        Record<'_metadata', OFFER_DATUM_TYPE>;

      if (utxo.data_hash) {
        try {
          metadata = await getOfferMetadata(utxo.data_hash);
        } catch (err) {
          if (!policy || !tokenName) {
            throw new Error('Unknown UTxO - Datum not held');
          }

          try {
            const onChainMetadata = await getOnchainMetadata(utxo);

            if (onChainMetadata && onChainMetadata.length > 0) {
              const metadataDtm = onChainMetadata.find(
                (m) => m.label === DATUM_LABEL.toString()
              );
              const metadataAddr = onChainMetadata.find(
                (m) => m.label === ADDRESS_LABEL.toString()
              );

              if (!metadataDtm || !metadataAddr) {
                throw new Error(
                  'Unknown Error - Invalid Metadata, need Address and Datum'
                );
              }
              const a = metadataDtm.json_metadata as {
                [index: string]: string | string[];
              };
              const datum = flattenIfArray(a[utxo.output_index]);
              const b = metadataAddr.json_metadata as string | string[];
              const tradeOwner = flattenIfArray(b);

              if (!tradeOwner || !datum)
                throw new Error('No TradeOwner or Datum');

              console.debug('Trade owner', tradeOwner);
              console.debug('datum', datum);

              const ownerAddr = Loader.Cardano.Address.from_bytes(
                fromHex(tradeOwner)
              );
              const ownerBaseAddress = Loader.Cardano.BaseAddress.from_address(
                ownerAddr
              )
                ?.payment_cred()
                .to_keyhash()
                ?.to_bytes();

              if (!ownerBaseAddress)
                throw new Error(
                  'Cannot convert Trade Owner Address to Base Address'
                );

              const pd = Loader.Cardano.PlutusData.from_bytes(fromHex(datum));

              if (
                toHex(Loader.Cardano.hash_plutus_data(pd).to_bytes()) !==
                utxo.data_hash
              ) {
                throw new Error('Onchain Metadata: Datum hash does not match.');
              }

              const cs = pd.as_constr_plutus_data();

              const price = cs?.data().get(3).as_integer()?.to_str();
              const startDatePOSIX = cs?.data().get(6).as_integer()?.to_str();

              if (!price)
                throw new Error(
                  '`fromDatum`: Unable to get price from PlutusData'
                );
              if (!startDatePOSIX)
                throw new Error(
                  '`fromDatum`: Unable to get startDatePOSIX from PlutusData'
                );

              const metadata = {
                [utxo.output_index]: datum,
                _uid: toHex(ownerAddr.to_bytes()),
                _metadata: {
                  ownerAddress: ownerBaseAddress,
                  price: Number.parseInt(price),
                  policy: policy,
                  assetName: tokenName,
                  startDatePOSIX: startDatePOSIX,
                },
              };
              return {
                datum: pd,
                metadata,
                dropspotAddress: DROPSPOT_ADDRESS,
                utxo: Loader.Cardano.TransactionUnspentOutput.new(
                  Loader.Cardano.TransactionInput.new(
                    Loader.Cardano.TransactionHash.from_bytes(
                      fromHex(utxo.tx_hash)
                    ),
                    utxo.output_index
                  ),
                  Loader.Cardano.TransactionOutput.new(
                    Loader.Cardano.Address.from_bech32(contractAddress),
                    assetsToValue(utxo.amount)
                  )
                ),
              };
            }
          } catch (e) {
            console.warn(e);
          }

          const mintRequests = await getMintRequestForPolicy(policy);

          if (
            mintRequests.length === 0 ||
            !mintRequests[0].assets ||
            mintRequests[0].assets.length === 0
          ) {
            throw new Error('No Minting Requests found');
          }

          if (mintRequests[0].type === 'Redemption') {
            throw new Error('Incorrect mint type for this process');
          }

          const token = mintRequests[0].tokens?.find(
            (t) => `${t.policy}${fromAscii(t.tokenName)}` === asset
          );
          const a = mintRequests[0].assets.find(
            (a) => a.assetId === token?.assetId
          );

          if (!a) {
            throw new Error('Asset not found');
          }
          const saleInfo = mintRequests[0].initialSaleInfo;
          console.log('DSM', 'saleInfo', JSON.stringify(saleInfo));
          const price = a.price;

          const ownerAddress = Loader.Cardano.Address.from_bech32(
            saleInfo.tradeOwnerAddress
          );

          const ownerBaseAddress = Loader.Cardano.BaseAddress.from_address(
            ownerAddress
          )
            ?.payment_cred()
            .to_keyhash()
            ?.to_bytes();

          if (!ownerBaseAddress) {
            throw new Error('ownerAddress is not workable');
          }

          const royalties =
            saleInfo.royalties.map((r) => ({
              percent: r.percent,
              address: Loader.Cardano.Address.from_bech32(r.address).to_bytes(),
            })) || [];

          const disbursements =
            saleInfo.split.map((r) => ({
              percent: r.percent,
              address: Loader.Cardano.Address.from_bech32(r.address).to_bytes(),
            })) || [];

          metadata = {
            [utxo.output_index]: toHex(
              OFFER({
                royalties,
                disbursements,
                assetName: tokenName,
                ownerAddress: toHex(ownerBaseAddress),
                policy,
                price: price,
                startDatePOSIX: saleInfo.startDatePOSIX,
              }).to_bytes()
            ),
            _uid: toHex(ownerAddress.to_bytes()),
            _metadata: {
              ownerAddress: ownerBaseAddress,
              price: price,
              policy: policy,
              assetName: tokenName,
              startDatePOSIX: saleInfo.startDatePOSIX,
            },
          };
        }
      } else {
        throw new Error('Unknown UTxO - Datum not held');
      }

      const datumCbor = metadata[utxo.output_index] as string;
      const datum = Loader.Cardano.PlutusData.from_bytes(fromHex(datumCbor));

      if (
        toHex(Loader.Cardano.hash_plutus_data(datum).to_bytes()) !==
        utxo.data_hash
      ) {
        throw new Error('Datum hash does not match.');
      }

      return {
        datum,
        metadata,
        dropspotAddress: DROPSPOT_ADDRESS,
        utxo: Loader.Cardano.TransactionUnspentOutput.new(
          Loader.Cardano.TransactionInput.new(
            Loader.Cardano.TransactionHash.from_bytes(fromHex(utxo.tx_hash)),
            utxo.output_index
          ),
          Loader.Cardano.TransactionOutput.new(
            Loader.Cardano.Address.from_bech32(contractAddress),
            assetsToValue(utxo.amount)
          )
        ),
      };
    })
  );
}

// function redeemerTagKindToText(tag: number) {
//   // 0 ; inputTag "Spend"
//   // / 1 ; mintTag  "Mint"
//   // / 2 ; certTag  "Cert"
//   // / 3 ; wdrlTag  "Reward"
//   switch (tag) {
//     case 0:
//       return 'spend';
//   }
// }

export const getOffer = _getOffer;
